import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import * as CONFIG from '@/setup/init.js'

Vue.use(Vuex)

const timeStamp = Math.floor(Date.now() / 1000)

if (!localStorage.getItem('langText')) {
  localStorage.setItem('langText', '{}')
}

export default new Vuex.Store({
  state: {    
    token: false,
    //userType: 'visitor',
    user: false,       
    shops: [],
    language_id: CONFIG.api_conf.defaultLanguageId,
    languages: CONFIG.api_conf.defLanguages,    
    langText: {},
    promoStatus: null, //before, live, after
    products:[],
    loginShow: 1   
  },
  mutations: {
    getShops(state, response) {
      state.shops = response;
    },
    getProds(state, response) {
      state.products = response;
    },
  },
  actions: {
    getShops({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'data/shops.json?' + timeStamp).then(response => {
          commit("getShops", response.data.shops)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    // getProducts({commit}) {
    //   return new Promise((resolve) => {
    //     axios.get(process.env.BASE_URL + 'data/products.json?' + timeStamp).then(response => {
    //       commit("getProds", response.data.products)
    //       resolve()
    //     })
    //       .catch(() => console.log('error getting endpoints'));
    //   })
    // },
  },
  modules: {
  }
})
